import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Grommet, Heading, Tab, Tabs } from "grommet";
import BookingProvider from "../../containers/booking/BookingContext";
import LocationForm from "../../containers/booking/LocationForm";
import CaptureDetails from "../../containers/booking/CaptureDetails";
import theme from "../../assets/theme";
import "./booking.scss";
import PropertyDetails from "../../containers/booking/PropertyDetails";
import useBooking from "../../containers/booking/useBooking";
import Schedule from "../../containers/booking/Schedule";
import Contact from "../../containers/booking/Contact";
import ServiceIntro from "../../containers/booking/ServiceIntro";

const Booking = () => {
  const { values } = useBooking();

  const activeTab = useMemo(() => {
    switch (values.currentTab) {
      case "property":
        return 0;
      case "schedule":
        return 1;
      case "contact":
        return 2;
      case "review":
        return 3;
      default:
        return 0;
    }
  }, [values.currentTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const onActive = useCallback(() => {}, []);
  return (
    <Grommet
      theme={theme}
      full
      background="light-2"
      style={{ height: "auto", minHeight: "100vh" }}
    >
      <div className="booking">
        <Heading level="2">Survey Details</Heading>
        <p>You're only a few steps away from booking your scan</p>
        <Box className="container" pad="large">
          <Box className="left-pane">
            <Tabs
              activeIndex={activeTab}
              onActive={onActive}
              justify="start"
            >
              <Tab title="1. PROPERTY DETAILS">
                {activeTab === 0 && (
                  <Box margin="small" style={{ marginTop: "30px" }}>
                    <LocationForm />
                    <CaptureDetails />
                    <PropertyDetails />
                  </Box>
                )}
              </Tab>
              <Tab title="2. SCHEDULE">
                {activeTab === 1 && (
                  <Box margin="small" style={{ marginTop: "30px" }}>
                    <Schedule />
                  </Box>
                )}
              </Tab>
              <Tab title="3. CONTACT">
                {activeTab === 2 && (
                  <Box margin="small" style={{ marginTop: "30px" }}>
                    <Contact />
                  </Box>
                )}
              </Tab>
            </Tabs>
          </Box>
          <div className="right-pane">
            <ServiceIntro />
          </div>
        </Box>
      </div>
    </Grommet>
  );
};

export default function () {
  return (
    <BookingProvider>
      <Booking />
    </BookingProvider>
  );
}
